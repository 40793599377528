import React, { useState, useEffect } from "react";

import Layout from "components/Global/Layout";
import ProductHero from "components/Product/Hero";
import ProductIngredients from "../components/Product/Ingredients";
import { data } from "../../content/data";
import ProductAvailability from "../components/Product/Availability";
import Can from "../components/Product/Can";

const Switchel = () => {
	const color = "#947119";
	const [distance, setDistance] = useState(0);
	const [scale, setScale] = useState(1);
	const [shouldShowCan, setShouldShowCan] = useState(false);
	const [shouldShowSymbols, setShouldShowSymbols] = useState(false);

	const handleScroll = () => {
		const position = window.pageYOffset;
		const windowWidth = window.innerWidth >= 1440 ? 1440 : window.innerWidth;
		const ratio = position / windowWidth;
		const value = ratio * 0.5 + 0.5;
		const top = window.pageYOffset;

		if (value < 0.75) {
			setDistance(ratio * 0.5 + 0.5);
			setShouldShowCan(true);
			setShouldShowSymbols(false);
			setScale(1);
		} else if (ratio < 1.25) {
			setDistance(ratio * -0.33 + 0.9166);
			setShouldShowCan(true);
			setShouldShowSymbols(true);
			setScale(ratio * -0.33 + 0.9166 + 0.25);
		} else {
			setDistance(0.5);
			setShouldShowCan(false);
			setShouldShowSymbols(false);
			setScale(0.75);
		}
	};

	useEffect(() => {
		if (window.innerWidth > 768) {
			handleScroll();
			window.addEventListener("scroll", handleScroll, {
				passive: true,
			});

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		} else {
			setDistance(0.5);
			setShouldShowCan(true);
		}
	}, []);

	return (
		<div
			className={`${shouldShowCan ? "show-can" : "hide-can"} ${
				shouldShowSymbols ? "show-symbols" : "show-ingredients"
			}`}
		>
			<Layout
				pageName="product"
				isDark
				color={color}
				amazon="https://www.amazon.com/dp/B0CKRXV1YV?maas=maas_adg_DD30A0B2C17A3E2FC5FAECD7B6A72B5F_afap_abs&ref_=aa_maas&tag=maas"
			>
				<style>
					{`
            .product-hero__body:first-of-type::first-letter {
              background-color: ${color};
            }
          `}
				</style>
				<div
					style={{
						transform: `translateX(-50%) scale(${scale})`,
						left: `${distance * 100}%`,
						opacity: `${shouldShowCan ? "1" : "0"}`,
						pointerEvents: `${shouldShowCan ? "all" : "none"}`,
					}}
					className="page-product__can"
				>
					<Can
						spin={data.switchel.can.spin}
						disc={data.switchel.can.disc}
						ingredients={data.switchel.can.ingredients}
						symbols={data.switchel.can.symbols}
					/>
				</div>

				<ProductHero
					title={data.switchel.hero.title}
					logo={data.switchel.hero.logo}
					description={data.switchel.hero.description}
					quotes={data.switchel.hero.quotes}
					hits={data.switchel.hero.hits}
					color={color}
					destinationLeft="/posca"
					destinationRight="/sekanjabin"
				/>

				<ProductIngredients
					color={color}
					title={data.switchel.ingredients.title}
					items={data.switchel.ingredients.items}
					descriptions={data.switchel.ingredients.descriptions}
					spin={data.switchel.can.spin}
					disc={data.switchel.can.disc}
					ingredients={data.switchel.can.ingredients}
				/>

				<ProductAvailability
					color={color}
					title={data.switchel.hero.title}
					logo={data.switchel.hero.logo}
					availability={data.switchel.availability}
					destinationLeft="/posca"
					destinationRight="/sekanjabin"
				/>
			</Layout>
		</div>
	);
};

export default Switchel;
